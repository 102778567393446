import styled from 'styled-components'

export const Section = styled.div`
  .list,
  .list-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

export const Header = styled.header`
  text-align: center;

  h1 {
    margin-top: 2em;
    margin-bottom: 0.625em;
  }
`

export const SegmentWrap = styled.div`
  display: inline-block;
  position: relative;
  width: 256px;
  background-color: white;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.25), 0 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 16px;
  margin-right: 30px;
  margin-bottom: 20px;
  cursor: pointer;

  &:last-of-type,
  &:nth-child(4n) {
    margin-right: 0;
  }

  .icon-caret-down {
    color: rgb(192, 198, 217);
    display: inline-block;
    position: absolute;
    right: -15px;
    font-size: 14px;
  }
`

export const AddSegmentWrap = styled.div`
  display: inline-block;
  position: relative;
  width: 256px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  text-align: center;

  i {
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  p {
    font-size: 1.6rem;
    font-weight: 500;
  }
`

export const SegmentGrid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
