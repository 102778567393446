import React, { useState, useEffect } from 'react'
import { Router, Redirect, Link } from '@reach/router'
import { ProvideAuth, useAuth } from './use-auth.js'
import { ProvideProject, useProject } from './use-project.js'

import Dashboard from './Dashboard'
import Journey from './Journey'
import Customers from './Customers'
import Segments from './Segments'
import Engagements from './Engagements'
import Integrations from './Integrations'

import Login from './ScreenLogin'
import LaunchPad from './LaunchPad'

import './App.css'

function Loading() {
  return <div> Loading...</div>
}

function App({ children }) {
  const [loading, setLoading] = useState(true)
  const { user } = useAuth()
  const { project, loadProject } = useProject()

  if (!user) {
    return <Login />
  }

  if (!project) {
    return <LaunchPad />
  }

  return (
    <Router>
      <Dashboard path="/">
        <Redirect from="/" to="/engagements" noThrow />
        <Journey path="journey" />
        <Customers path="customers" />
        <Engagements path="engagements" />
        <Segments path="segments" />
        <Integrations path="integrations" />
      </Dashboard>
    </Router>
  )
}

export default App
