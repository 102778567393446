import React from 'react'
import { Icon } from 'antd'

function LoadingSpinner() {
  return (
    <div style={{ textAlign: 'center' }}>
      <Icon type="loading" />
      <p>loading ...</p>
    </div>
  )
}

export default LoadingSpinner
