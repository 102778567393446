import React from 'react'
import { Router, Redirect, Link } from '@reach/router'

const Integrations = () => {
  return (
    <div className="integrations">
      <h1>Integrations</h1>
    </div>
  )
}

export default Integrations
