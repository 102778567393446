import React from 'react'
import { Router, Redirect, Link } from '@reach/router'
import { Icon } from 'antd'
import { Panel } from './Layout.style'
import {
  Section,
  Header,
  SegmentGrid,
  SegmentWrap,
  AddSegmentWrap
} from './Journey.style'

const Segment = ({ name, metric }) => {
  return (
    <SegmentWrap>
      <div className="">
        <h3>{name}</h3>
      </div>
      {metric ? <h2>~0%</h2> : <h2>Define</h2>}
      {metric ? (
        <p>of active customers (estimated)</p>
      ) : (
        <p>to start tracking</p>
      )}
      <footer>{/* details */}</footer>
    </SegmentWrap>
  )
}

const AddSegment = ({ isMilestone }) => {
  return (
    <AddSegmentWrap>
      <Icon type="plus" />
      <p>Add another {isMilestone ? 'milestone' : 'segment'}</p>
    </AddSegmentWrap>
  )
}

const Journey = () => {
  return (
    <Panel className="Journey">
      <Section>
        <Header>
          <h1>Customer Milestones</h1>
        </Header>
        <SegmentGrid>
          <Segment name="Evaluators" milestone={1} />
          <Segment name="Beginners" milestone={2} />
          <Segment name="Regulars" milestone={3} />
          <Segment name="Champions" milestone={4} />
          <AddSegment isMilestone />
        </SegmentGrid>
      </Section>

      <Section>
        <Header>
          <h1>Your Segments</h1>
        </Header>
        <SegmentGrid>
          <AddSegment />
        </SegmentGrid>
      </Section>
    </Panel>
  )
}

export default Journey
