import React, { useState, useEffect, useContext, createContext } from 'react'
import store from 'store'
import find from 'lodash/find'

import services from './services'
import { useAuth } from './use-auth.js'

import FullPageSpinner from './FullPageSpinner'

const projectContext = createContext()

export function ProvideProject({ children }) {
  const [project, setProject] = useState(null)
  const { user } = useAuth()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (user) {
      loadProject().then(() => setLoading(false))
    } else {
      setLoading(false)
    }
  }, [user])

  function loadProject() {
    return services.projects.list().then(projects => {
      const _project = projects.length
        ? find(projects, {
            _id: store.get('projectId') || projects[0]._id
          })
        : null
      setProject(_project)
      return Promise.resolve(_project)
    })
  }

  function createProject(data) {
    return services.projects.create(data).then(() => {
      return loadProject()
    })
  }

  return loading ? (
    <FullPageSpinner />
  ) : (
    <projectContext.Provider value={{ project, createProject }}>
      {children}
    </projectContext.Provider>
  )
}

export const useProject = () => {
  return useContext(projectContext)
}
