import axios from 'axios'
import extend from 'lodash/extend'

import Config from './config'

let token

const request = axios.create({
  baseURL: Config.servicesHost,
  timeout: Config.httpTimeout
})

function method(config) {
  const opts = extend({ headers: { Authorization: token } }, config)

  return request(opts)
    .then(function(res) {
      return Promise.resolve(res.data)
    })
    .catch(function(error) {
      //logger.notify(error)
      return Promise.reject(error)
    })
}

export function setToken(t) {
  token = t
}

function signUp(data) {
  return method({
    method: 'post',
    url: '/users',
    data
  })
}

function signIn(data) {
  return method({
    method: 'post',
    url: '/login',
    data
  })
}

function auth(data) {
  return method({
    method: 'get',
    url: '/users/me'
  })
}

function signOut() {
  return method({
    method: 'post',
    url: '/logout'
  }).then(res => {
    token = null
    return Promise.resolve(res)
  })
}

function listProjects() {
  return method({
    method: 'get',
    url: '/projects'
  }).then(res => {
    return Promise.resolve(res.data)
  })
}

function createProject({ name, domain }) {
  return method({
    method: 'post',
    url: '/projects',
    data: { name, domain }
  }).then(res => {
    return Promise.resolve(res)
  })
}

function listEngagements({ project }) {
  return method({
    method: 'get',
    url: '/engagements',
    params: { project }
  }).then(res => {
    return Promise.resolve(res.items)
  })
}

function listCustomers({ project, limit, startAfter }) {
  return method({
    method: 'get',
    url: '/customers',
    params: { project, limit, start_after: startAfter }
  })
}

const services = {
  auth,
  signUp,
  signIn,
  signOut,

  projects: {
    create: createProject,
    list: listProjects
  },

  engagements: {
    list: listEngagements
  },

  customers: {
    list: listCustomers
  }
}

export default services
