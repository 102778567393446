import styled from 'styled-components'

export const DashboardWrap = styled.div`
  min-height: 100%;
`

export const NavBarWrap = styled.header`
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 40px;

  /*border-bottom: 1px solid #dedddc;*/
  /*box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.05)*/
  outline: 0;
  background-color: #fbfbfb;
  z-index: 9000;

  .logo {
    left: 0;
    top: 0;
    position: absolute;
    height: 3.9rem;
    padding: 0.5rem 0.8rem 0;
  }

  .nav {
    display: flex;
    flex-direction: row;
    margin-left: 30px;
    align-items: center;
    font-size: 1.4rem;

    .nav-item {
      padding: 0 20px;
      padding: 1.3rem 1.2rem 1.1rem 3.5rem;
      font-size: 1.5rem;

      a {
        display: flex;
        align-items: center;
        font-weight: bold;
        color: inherit;

        i {
          font-size: 26px;
          font-weight: bold;
          padding-right: 8px;
        }
      }
    }
  }

  .accountBtn {
    position: absolute;
    right: 0;
    top: 0;
    padding: 0.5rem 0.8rem 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    .title {
      text-transform: capitalize;
      /*color: #353535;*/
      font-weight: bold;
      padding-right: 15px;
    }
  }
`
