import React, { useState } from 'react'
import { Redirect, navigate } from '@reach/router'
import { useFormik } from 'formik'
import { useAuth } from '../use-auth.js'
import { clue } from '../react-clue'
import { ReactComponent as Logo } from '../logo.svg'

import './style.scss'

const Login = () => {
  const [error, setError] = useState(false)
  const { authing, user, signin } = useAuth()

  const formik = useFormik({
    initialValues: initialFormValues(),
    onSubmit: handleLogin
  })

  function initialFormValues() {
    return {
      email: '',
      password: ''
    }
  }

  function handleLogin({ email, password }) {
    signin({ email, password })
      .then(user => {
        clue('identify', user.id)
        clue('set_profile', { name: user.name, email: user.email })
        setTimeout(function() {
          navigate('/')
        }, 0)
      })
      .catch(error => setError(true))
  }

  return (
    <div className="login-screen">
      <div className="brand-wrapper">
        <Logo className="logo" />
        <form className="login-form" onSubmit={formik.handleSubmit}>
          <h1>Log in to Cluedrop</h1>
          <input
            className="input--text push_half--bottom"
            type="text"
            name="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            placeholder="Email"
          ></input>
          <input
            className="input--text push_half--bottom"
            type="password"
            name="password"
            onChange={formik.handleChange}
            value={formik.values.password}
            placeholder="Password"
          ></input>
          <input type="submit" className="btn-action btn-action--login"></input>
          {error ? (
            <p className="error--login txt--red">
              Incorrect credentials, please try again
            </p>
          ) : null}
        </form>
      </div>
    </div>
  )
}

export default Login
