function loadPlayer(options) {
  ;(function(w, d, s, r, i) {
    w[r] =
      w[r] ||
      function() {
        ;(w[r].q = w[r].q || []).push(arguments)
      }
    w[r].l = 1 * new Date()
    var a = d.createElement(s),
      m = d.getElementsByTagName(s)[0]
    a.async = 1
    a.src = 'https://d2xkq1vvk8u7fn.cloudfront.net/clue.js?id=' + i
    m.parentNode.insertBefore(a, m)
  })(window, document, 'script', 'clue', options.playerId)
}

function internalClue(...args) {
  if (typeof window.clue === 'function') {
    window.clue(...args)
  }
}

export function initialize(playerId) {
  loadPlayer({ playerId })
  internalClue('init', playerId)
}

export function clue(...args) {
  if (args.length > 0) {
    internalClue(...args)
  }

  return window.clue
}
