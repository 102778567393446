import styled from 'styled-components'

export const Panel = styled.div`
  padding: 0 1.6rem;
  margin: 0 auto 10rem;
  max-width: 48em;

  &.Journey {
    max-width: 1120px;
    padding: 0;
  }
`
