import React, { Fragment, useState, useEffect } from 'react'
import { Router, Redirect, Link } from '@reach/router'
import { Button, Drawer, Tag, Icon } from 'antd'
import Moment from 'moment-timezone'

import services from './services'
import { useProject } from './use-project'

import LoadingSpinner from './LoadingSpinner'

import './Engagement.style.scss'
import StepIconAlert from './svg/type-alert.svg'
import StepIconModal from './svg/type-modal.svg'
import OverflowIcon from './svg/overflow.svg'

const stepTypeToIcon = {
  tooltip: StepIconAlert
}

function convertTime(ts) {
  return Moment.unix(ts)
    .tz('America/Los_Angeles')
    .format('ddd, MMMM Do')
}

function EngagemntItem(props) {
  const [showOptions, setShowOptions] = useState(false)
  const { name, published, publishedAt, updatedAt, steps, stats } = props
  const publishedOn = convertTime(publishedAt)
  const updatedOn = convertTime(updatedAt)
  const status = published ? 'Live' : 'Draft'
  const views = (stats || {}).views || 0
  const avgInteractionRate = (stats || {}).avgInteraction || 0

  function handleShowOptions() {
    setShowOptions(true)
  }

  function handleCloseOptions() {
    setShowOptions(false)
  }

  return (
    <div className="card" style={{ overflow: 'hidden' }}>
      <div className="card__details">
        <div className="card__title">
          <h2>Welcome Tour</h2>
        </div>
        <div className="card__meta">
          <p className="published-mark">
            <Tag className="tag--published" color="#d8eacc">
              {status}
            </Tag>{' '}
            {published ? publishedOn : updatedOn}
          </p>
        </div>
        <div className="card__steps">
          {steps.map(step => (
            <img
              key={step._id}
              className="card__step-icon"
              src={stepTypeToIcon[step.elementType]}
              onClick={() => alert('selected a step')}
            ></img>
          ))}
        </div>
      </div>
      <div className="card__stats">
        <div className="card__stat">
          <p className="card__stat-number">{views}</p>
          <p className="card__stat-meta">Views</p>
        </div>
        <div className="card__stat">
          <p className="card__stat-number">{avgInteractionRate}%</p>
          <p className="card__stat-meta">Avg. Interaction</p>
        </div>
        <div className="card__stat" style={{ opacity: 0.15 }}>
          <p className="card__stat-number">0</p>
          <p className="card__stat-meta">Achievements</p>
        </div>
      </div>
      <div className="card__actions">
        <button
          type="button"
          className="btn-overflow"
          onClick={handleShowOptions}
        ></button>
      </div>
      <Drawer
        placement="right"
        closable={false}
        onClose={handleCloseOptions}
        visible={showOptions}
        getContainer={false}
        closable={true}
        mask={false}
        style={{ position: 'absolute' }}
      >
        <div className="card-drawer">
          <div className="card-drawer-option">
            <a>
              <Icon type="edit" theme="filled" />
              Edit engagement
            </a>
          </div>
          <div className="card-drawer-option">
            {published ? (
              <a>
                <Icon type="eye-invisible" theme="filled" />
                Unpublish engagement
              </a>
            ) : (
              <a>
                <Icon type="cloud" theme="filled" />
                Publish engagement
              </a>
            )}
          </div>
        </div>
      </Drawer>
    </div>
  )
}

function EngagementItems({ loading, items }) {
  return (
    <div className="engagement-list">
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Fragment>
          {items.map(item => (
            <EngagemntItem key={item._id} {...item} />
          ))}
        </Fragment>
      )}
    </div>
  )
}

const Elements = () => {
  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState([])
  const { project } = useProject()

  useEffect(() => {
    services.engagements.list({ project: project._id }).then(engagements => {
      setItems(engagements)
      setLoading(false)
    })
  }, [])

  return (
    <div className="EngagementScreen">
      <div className="inner">
        <header>
          <h1 className="title">Engagements</h1>
        </header>
        <EngagementItems loading={loading} items={items} />
      </div>
    </div>
  )
}

export default Elements
