import React, { useState, useEffect } from 'react'
import { Router, Redirect, Link } from '@reach/router'
import Moment from 'moment-timezone'
import services from '../services'
import { useProject } from '../use-project'

import { Avatar, Button, Table, Divider, Tag } from 'antd'
import LoadingSpinner from '../LoadingSpinner'

import './style.scss'

const columns = [
  {
    title: 'Customer',
    key: 'customer',
    ellipsis: true,
    width: '40%',
    render: record => {
      return (
        <div>
          <h4>{(record.traits || {}).name || record.identifier}</h4>
          <p>{(record.traits || {}).email}</p>
        </div>
      )
    }
  },
  {
    title: 'Segments',
    dataIndex: 'segments',
    width: '40%',
    render: tags => (
      <span>
        {(tags || []).map(tag => {
          let color = tag.length > 5 ? 'geekblue' : 'green'
          if (tag === 'loser') {
            color = 'volcano'
          }
          return (
            <Tag color={color} key={tag}>
              {tag.toUpperCase()}
            </Tag>
          )
        })}
      </span>
    )
  },
  {
    title: 'Last Seen',
    key: 'last_seen',
    ellipsis: true,
    width: '20%',
    render: record => (
      <span>
        {Moment.unix(record.lastSeenAt)
          .tz('America/Los_Angeles')
          .fromNow()}
      </span>
    )
  }
  /*
  {
    title: 'Action',
    key: 'action',
    width: '20%',
    render: (text, record) => (
      <span>
        <a>Invite {record.name}</a>
        <Divider type="vertical" />
        <a>Delete</a>
      </span>
    )
  }*/
]

const data = [
  {
    key: '1',
    traits: {
      name: 'John Brown',
      email: 'john@gmail.com'
    },
    tags: ['nice', 'developer']
  },
  {
    key: '2',
    traits: {
      name: 'Jim Green',
      email: 'Jim@gmail.com'
    },
    tags: ['loser']
  },
  {
    key: '3',
    traits: {
      name: 'Joe Black',
      email: 'joe@gmail.com'
    },
    tags: ['cool', 'teacher']
  }
]

const Customers = () => {
  const [customers, setCustomers] = useState()
  const [loading, setLoading] = useState(true)
  const { project } = useProject()

  useEffect(() => {
    handleLoadCustomers()
  }, [])

  function handleLoadCustomers() {
    setLoading(true)
    services.customers.list({ project: project._id }).then(list => {
      setCustomers(list.data)
      setLoading(false)
    })
  }

  return (
    <div className="CustomerScreen">
      <div className="inner">
        <h1 className="title">Customers</h1>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <Table
            tableLayout="fixed"
            rowKey="_id"
            className="table"
            columns={columns}
            dataSource={customers}
            rowClassName="table__row"
          />
        )}
      </div>
    </div>
  )
}

export default Customers
