import React from 'react'
import { Router, Redirect, Link, navigate } from '@reach/router'
import { Flags } from 'react-feature-flags'
import { useAuth } from './use-auth.js'
import { useProject } from './use-project.js'

import { Menu, Dropdown, Avatar, Badge, Icon } from 'antd'

import NavLink from './NavLink'

import logo from './logo.svg'
import { DashboardWrap, NavBarWrap } from './Dashboard.style'

const AccountNavItem = ({ title }) => {
  const { signout } = useAuth()

  function handleShowProfile() {
    console.log('show profile')
  }

  function handleShowAdmin() {
    console.log('show adminland')
  }

  function handleShowInvite() {
    console.log('invite a coworker')
  }

  function handleSignout() {
    signout().then(() => navigate('/'))
  }

  const menu = (
    <Menu style={{ marginTop: 3 }}>
      <Menu.Item key="0">
        <a onClick={handleShowProfile}>My Profile</a>
      </Menu.Item>
      <Menu.Item key="1">
        <a onClick={handleShowAdmin}>Adminland</a>
      </Menu.Item>
      <Menu.Item key="2">
        <a onClick={handleShowInvite}>Invite Coworker</a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="3" onClick={handleSignout}>
        <a onClick={handleSignout}>Log Out</a>
      </Menu.Item>
    </Menu>
  )

  return (
    <div className="accountBtn">
      <Dropdown overlay={menu} trigger={['click']}>
        <div>
          <span className="title">{title}</span>
          <Badge count={0}>
            <Avatar
              shape="circle"
              size={30}
              style={{
                color: '#f56a00',
                backgroundColor: '#fde3cf',
                fontSize: 12,
                fontWeight: 700
              }}
            >
              ML
            </Avatar>
          </Badge>
        </div>
      </Dropdown>
    </div>
  )
}

const Loading = () => {
  return <div> loading cluedrop</div>
}

const NavBar = () => {
  return (
    <NavBarWrap>
      <img className="logo" src={logo} />
      <nav className="nav">
        <Flags authorizedFlags={['journey']}>
          <div className="nav-item">
            <NavLink to="journey">
              <Icon type="compass" />
              Journey
            </NavLink>
          </div>
        </Flags>

        <div className="nav-item">
          <NavLink to="engagements">
            <Icon type="gold" />
            Engagements
          </NavLink>
        </div>

        <div className="nav-item">
          <NavLink to="customers">
            <Icon type="idcard" />
            Customers
          </NavLink>
        </div>

        <Flags authorizedFlags={['segments']}>
          <div className="nav-item">
            <NavLink to="segments">
              <Icon type="deployment-unit" />
              Segments
            </NavLink>
          </div>
        </Flags>

        <div className="nav-item">
          <NavLink to="integrations">
            <Icon type="api" />
            Integrations
          </NavLink>
        </div>
      </nav>
      <AccountNavItem title="" />
    </NavBarWrap>
  )
}

const Dashboard = props => {
  const { user } = useAuth()
  const { project } = useProject()

  return (
    <DashboardWrap>
      <NavBar />
      <div>{props.children}</div>
    </DashboardWrap>
  )
}

export default Dashboard
