import React from 'react'
import App from './App'
import { FlagsProvider } from 'react-feature-flags'
import { ProvideAuth, useAuth } from './use-auth.js'
import { ProvideProject, useProject } from './use-project.js'

import flags from './flags'

function Container() {
  return (
    <FlagsProvider value={flags}>
      <ProvideAuth>
        <ProvideProject>
          <App />
        </ProvideProject>
      </ProvideAuth>
    </FlagsProvider>
  )
}

export default Container
