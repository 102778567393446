import React from 'react'
import ReactDOM from 'react-dom'
import Container from './Container'
import * as serviceWorker from './serviceWorker'
import * as cluedrop from './react-clue'

import './index.css'

if (process.env.NODE_ENV === 'production') {
  cluedrop.initialize('ed3b1f5b-e5c2-4e6e-b57f-9d07224b714e')
}

ReactDOM.render(<Container />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
