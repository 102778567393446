import React from 'react'
import { Link } from '@reach/router'

export default ({ partial = true, ...props }) => (
  <Link
    {...props}
    getProps={({ isCurrent, isPartiallyCurrent }) => {
      const isActive = partial ? isPartiallyCurrent : isCurrent
      return {
        style: {
          //opacity: isActive ? 1 : 0.9 //'#ee6c4d' : ''
        }
      }
    }}
  />
)
