import React, { useState } from 'react'
import { Redirect, navigate } from '@reach/router'
import { useFormik } from 'formik'
import { useAuth } from './use-auth.js'
import { useProject } from './use-project.js'

const LaunchPad = () => {
  const [error, setError] = useState(false)
  const { user } = useAuth()
  const { project, createProject } = useProject()

  const formik = useFormik({
    initialValues: initialFormValues(),
    onSubmit: handleCreateProject
  })

  function initialFormValues() {
    return {
      name: '',
      domain: ''
    }
  }

  function handleCreateProject(data) {
    createProject(data)
      .then(() => navigate('/'))
      .catch(error => setError(error))
  }

  return (
    <div>
      <h1> Let's create a project</h1>
      {error ? <p>error</p> : null}
      <form onSubmit={formik.handleSubmit}>
        <label>Project Name</label>
        <input
          type="text"
          name="name"
          onChange={formik.handleChange}
          value={formik.values.name}
        ></input>
        <label>Project Domain</label>
        <input
          type="text"
          name="domain"
          onChange={formik.handleChange}
          value={formik.values.domain}
        ></input>
        <input type="submit"></input>
      </form>
    </div>
  )
}

export default LaunchPad
