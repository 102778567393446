import React, { useState, useEffect, useContext, createContext } from 'react'
import store from 'store'
import FullPageSpinner from './FullPageSpinner'
import services, { setToken } from './services'

const authContext = createContext()

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const [user, setUser] = useState(null)
  const [session, setSession] = useState(null)
  const [authenticating, setAuthenticating] = useState(store.get('session'))

  useEffect(() => {
    if (authenticating) {
      const prevSession = store.get('session')
      auth(prevSession.token)
        .then(session => {
          setUser(session.user)
          setSession(session.login)
          store.set('session', session.login)
          return Promise.resolve(session.user)
        })
        .catch(error => {
          setSession(null)
          setUser(null)
          store.remove('session')
        })
        .finally(() => {
          setAuthenticating(false)
        })
    }
  }, [authenticating])

  const auth = token => {
    setToken(token)
    return services.auth()
  }

  const signin = data => {
    return services.signIn(data).then(session => {
      setToken(session.login.token)
      setUser(session.user)
      setSession(session.login)

      store.set('session', session.login)
      return Promise.resolve(session.user)
    })
  }

  const signup = data => {
    return services.signIn(data).then(session => {
      setUser(session.user)
      setSession(session.login)
      setToken(session.login.token)
      store.set('session', session.login)
      return Promise.resolve(session.user)
    })
  }

  const signout = () => {
    return services.signOut().then(() => {
      setUser(null)
      setSession(null)
      store.remove('session')
    })
  }

  const sendPasswordResetEmail = email => {
    /*return firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        return true
      })*/
  }

  const confirmPasswordReset = (code, password) => {
    /*return firebase
      .auth()
      .confirmPasswordReset(code, password)
      .then(() => {
        return true
      })*/
  }

  return authenticating ? (
    <FullPageSpinner />
  ) : (
    <authContext.Provider
      value={{
        user,
        session,
        signin,
        signup,
        signout,
        sendPasswordResetEmail,
        confirmPasswordReset
      }}
    >
      {children}
    </authContext.Provider>
  )
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext)
}
